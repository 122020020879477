<div id="controls" [class.controls--disabled]="disabled === true">
  <div class="flex flex-row items-center justify-center">
    <hypecast-image [customClass]="'episode__column--image'"
                    [customContainerClass]="'episode__column--image'"
                    [src]="currentItem?.episode?.season?.podcast?.type === PodcastType.Internal ? currentItem?.episode?.imageLarge.fileSource : currentItem?.episode?.externalImageLink"
                    [loadingContainerClass]="'episode__column--image'"
                    [loadingClass]="'episode__column--image'">
    </hypecast-image>
    <div class="cursor-pointer ml-3 flex-initial leading-none" (click)="isPlaying === true ? pause() : play()">
      <mat-spinner color="primary" *ngIf="isLoading === true" [diameter]="30"></mat-spinner>
      <mat-icon *ngIf="isLoading === false" class="icon">{{ isPlaying === true ? 'pause_circle_outline' :
        'play_circle_outline' }}</mat-icon>
    </div>
    <div #timer class="controls__timer ml-3 mr-1 flex-initial w-12">
      0:00
    </div>
    <div class="controls__slider flex-auto w-10">
      <mat-slider [attr.disabled]="disabled" (input)="setTimerWhenDragging($event)" #slider class="w-full" [min]="min"
                  [max]="max" [step]="stepSize" [formControl]="sliderControl">
      </mat-slider>
    </div>
    <div class="controls__duration text-right flex-initial w-12">
      {{ duration | minuteSeconds }}
    </div>
  </div>
</div>
