import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {
  HypecastAuthenticationService,
  HypecastCredentials,
} from '@app/shared/authentication/services/authentication.service';
import {__} from '@app/shared/functions/object.functions';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Logger} from '../logger.service';
import {RouteService} from '../route.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: HypecastAuthenticationService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const data = RouteService.MergeData(route);

    return this.authenticationService
      .credentials$
      .pipe(
        map((value: HypecastCredentials) => {
          if (!__.IsNullOrUndefined(data['roles'])) {
            const allowed = this.authenticationService.hasAnyRole(data['roles']);
            if (allowed) {
              return true;
            } else {
              // TODO: What to do on forbidden case?
              // return this.checkForbidden(route);
            }
          }

          return !__.IsNullOrUndefined(value)
        })
      );
  }
}
