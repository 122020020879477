import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AuthorizationInterceptor } from '@app/core/http/authorization.interceptor';
import { SharedModule } from '@app/shared';
import { DefaultOAuthInterceptor } from '@app/shared/authentication/angular-oauth-oidc/interceptors/default-oauth.interceptor';
import { AudioPlayerControlModule } from '@app/shared/components/audio-player-control/audio-player-control.module';
import { AudioPlayerService } from '@app/shared/components/audio-player-control/audio-player.service';
import { AuthorizedModule } from '@app/shared/components/authorized/authorized.module';
import { BreadcrumbsModule } from '@app/shared/components/breadcrumbs/breadcrumbs.module';
import { ImageModule } from '@app/shared/components/image/image.module';
import { LoadingModule } from '@app/shared/components/loading/loading.module';
import {
  FormDeactivateableDialogModule,
} from '@app/shared/route-guards/form-deactivateable/dialog/form-deactivateable-dialog.module';
import { FormDeactiviationGuard } from '@app/shared/route-guards/form-deactivateable/form-deactiviation.guard';
import { ApiUrlService } from '@app/shared/services/api-url.service';
import { ForbiddenService } from '@app/shared/services/forbidden.service';
import { ITenantService } from '@app/shared/services/itenant.service';
import { TenantService } from '@app/shared/services/tenant.service';
import { UsersService } from '@app/shared/services/users.service';

import { AuthenticationGuard } from './authentication/authentication.guard';
import { CustomRouteReuseStrategy } from './custom-route-reusable-stragey';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { HttpCacheService } from './http/http-cache.service';
import { HttpService } from './http/http.service';
import { I18nService } from './i18n.service';
import { BaseLayoutComponent } from './layouts/base/base-layout.component';
import { RouteReusableStrategy } from './route-reusable-strategy';

@NgModule({
  imports: [
    SharedModule,
    HttpClientModule,
    RouterModule,
    BreadcrumbsModule,

    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ImageModule,
    MatMenuModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    AudioPlayerControlModule,
    MatAutocompleteModule,
    LoadingModule,
    MatProgressSpinnerModule,
    AuthorizedModule,

    FormDeactivateableDialogModule,
  ],
  declarations: [
    BaseLayoutComponent
  ],
  providers: [
    {
      provide: HttpClient,
      useClass: HttpService
    },
    { provide: ITenantService, useClass: TenantService },
    ApiUrlService,
    ForbiddenService,
    FormDeactiviationGuard,
    DefaultOAuthInterceptor, // needs to be above authentication service
    AuthorizationInterceptor,
    AuthenticationGuard,
    HttpCacheService,
    ApiPrefixInterceptor,
    UsersService,
    AudioPlayerService,
    ErrorHandlerInterceptor,
    I18nService,
    CacheInterceptor,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    }
  ]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }

    this.matIconRegistry.addSvgIconLiteral('microphone', this.domSanitizer.bypassSecurityTrustHtml(MICROPHONE_ICON));
    this.matIconRegistry.addSvgIconLiteral('arrow_down', this.domSanitizer.bypassSecurityTrustHtml(ARROW_DOWN_ICON));
    this.matIconRegistry.addSvgIconLiteral('bar_chart', this.domSanitizer.bypassSecurityTrustHtml(BAR_CHART_ICON));
    this.matIconRegistry.addSvgIconLiteral('business_woman', this.domSanitizer.bypassSecurityTrustHtml(BUSINESS_WOMAN_ICON));
    this.matIconRegistry.addSvgIconLiteral('file', this.domSanitizer.bypassSecurityTrustHtml(FILE_ICON));
    this.matIconRegistry.addSvgIconLiteral('search', this.domSanitizer.bypassSecurityTrustHtml(SEARCH_ICON));
    this.matIconRegistry.addSvgIconLiteral('home', this.domSanitizer.bypassSecurityTrustHtml(HOME_ICON));
  }
}

const MICROPHONE_ICON = `
<svg id="microphone_4" width="32" height="32" viewBox="0 0 32 32" x="1120" y="2800">
<path d="M16 27.749c-5.981 0-10.667-4.926-10.667-11.214v-4.255c0-0.294 0.24-0.533 0.533-0.533h1.6c0.293 0 0.533 0.239 0.533 0.533 0 0.293-0.24 0.533-0.533 0.533h-1.067v3.722c0 5.785 4.127 10.147 9.6 10.147s9.6-4.351 9.6-10.121v-3.748h-1.067c-0.293 0-0.533-0.24-0.533-0.533 0-0.294 0.24-0.533 0.533-0.533h1.6c0.293 0 0.533 0.239 0.533 0.533v4.283c0 6.272-4.686 11.186-10.667 11.186z"></path>
<path d="M16 25.083c-4.705 0-8.533-3.835-8.533-8.547v-7.974c0-4.729 3.828-8.575 8.533-8.575s8.533 3.809 8.533 8.492v8.083c0 4.7-3.828 8.522-8.533 8.522zM16 1.054c-4.116 0-7.467 3.369-7.467 7.508v7.974c0 4.124 3.35 7.481 7.467 7.481s7.467-3.344 7.467-7.454v-8.084c0-4.095-3.35-7.425-7.467-7.425z"></path>
<path d="M15.467 31.481c-0.293 0-0.533-0.24-0.533-0.533v-3.2c0-0.294 0.24-0.533 0.533-0.533s0.533 0.239 0.533 0.533v3.2c0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M20.267 32.014h-9.6c-0.293 0-0.533-0.24-0.533-0.533 0-0.294 0.24-0.533 0.533-0.533h9.6c0.293 0 0.533 0.239 0.533 0.533 0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M20.267 6.414h-8.533c-0.293 0-0.533-0.24-0.533-0.533 0-0.294 0.24-0.533 0.533-0.533h8.533c0.293 0 0.533 0.239 0.533 0.533 0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M20.267 10.681h-8.533c-0.293 0-0.533-0.24-0.533-0.533 0-0.294 0.24-0.533 0.533-0.533h8.533c0.293 0 0.533 0.239 0.533 0.533 0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M20.267 14.947h-8.533c-0.293 0-0.533-0.24-0.533-0.533 0-0.294 0.24-0.533 0.533-0.533h8.533c0.293 0 0.533 0.239 0.533 0.533 0 0.293-0.24 0.533-0.533 0.533z"></path>
</svg>
`;

const ARROW_DOWN_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
`;

const BAR_CHART_ICON = `
<svg id="bar_chart" width="32" height="32" viewBox="0 0 32 32" x="1176" y="280">
<path d="M2.133 0h1.067v32h-1.067v-32z"></path>
<path d="M0 29.867h32v1.067h-32v-1.067z"></path>
<path d="M15.467 27.733h-2.133c-0.294 0-0.533-0.24-0.533-0.533v-11.733c0-0.294 0.239-0.533 0.533-0.533h2.133c0.294 0 0.533 0.239 0.533 0.533v11.733c0 0.293-0.239 0.533-0.533 0.533zM13.867 26.667h1.067v-10.667h-1.067v10.667z"></path>
<path d="M9.067 27.733h-2.133c-0.294 0-0.533-0.24-0.533-0.533v-6.4c0-0.293 0.239-0.533 0.533-0.533h2.133c0.294 0 0.533 0.24 0.533 0.533v6.4c0 0.293-0.239 0.533-0.533 0.533zM7.467 26.667h1.067v-5.333h-1.067v5.333z"></path>
<path d="M21.867 27.733h-2.133c-0.293 0-0.533-0.24-0.533-0.533v-6.4c0-0.293 0.24-0.533 0.533-0.533h2.133c0.293 0 0.533 0.24 0.533 0.533v6.4c0 0.293-0.24 0.533-0.533 0.533zM20.267 26.667h1.067v-5.333h-1.067v5.333z"></path>
<path d="M28.267 27.733h-2.133c-0.293 0-0.533-0.24-0.533-0.533v-12.8c0-0.294 0.24-0.533 0.533-0.533h2.133c0.293 0 0.533 0.239 0.533 0.533v12.8c0 0.293-0.24 0.533-0.533 0.533zM26.667 26.667h1.067v-11.733h-1.067v11.733z"></path>
<path d="M5.562 19.011c-0.153 0-0.302-0.065-0.407-0.191-0.19-0.223-0.162-0.56 0.063-0.75l9.332-7.889c0.212-0.178 0.526-0.166 0.722 0.031l4.9 4.911 7.734-7.801c0.206-0.209 0.546-0.21 0.754-0.003s0.211 0.545 0.003 0.754l-8.111 8.181c-0.101 0.101-0.237 0.157-0.378 0.157v0c-0.142 0-0.277-0.057-0.378-0.156l-4.932-4.944-8.957 7.571c-0.103 0.087-0.225 0.128-0.347 0.128z"></path>
</svg>
`;

const BUSINESS_WOMAN_ICON = `
<svg id="business_woman" width="32" height="32" viewBox="0 0 32 32" x="1176" y="560">
<path d="M29.867 31.266c-0.293 0-0.533-0.239-0.533-0.533v-1.467c0-3.397-5.012-4.662-8.171-4.662-0.162 0-0.318-0.074-0.419-0.203l-3.036-3.862c-0.183-0.231-0.143-0.566 0.090-0.75 0.233-0.181 0.566-0.142 0.748 0.090l2.877 3.662c3.707 0.069 8.977 1.62 8.977 5.726v1.467c0.001 0.294-0.239 0.533-0.532 0.533z"></path>
<path d="M2.133 31.266c-0.294 0-0.533-0.239-0.533-0.533v-1.467c0-4.106 5.188-5.655 8.837-5.726l2.906-3.663c0.182-0.231 0.518-0.271 0.749-0.087s0.27 0.518 0.086 0.75l-3.063 3.862c-0.101 0.127-0.255 0.201-0.418 0.201-3.105 0-8.030 1.266-8.030 4.662v1.467c0 0.295-0.239 0.534-0.533 0.534z"></path>
<path d="M15.722 20.466c-7.729 0-10.483-4.308-11.143-5.628-0.081-0.163-0.074-0.356 0.019-0.512 0.635-1.060 2.34-4.058 2.605-5.182 1.263-5.345 4.367-8.411 8.519-8.411s7.257 3.066 8.519 8.411c0.265 1.125 1.97 4.122 2.604 5.182 0.093 0.156 0.1 0.35 0.019 0.512-0.66 1.318-3.413 5.628-11.142 5.628zM5.666 14.613c0.786 1.398 3.385 4.786 10.055 4.786s9.267-3.388 10.055-4.786c-0.534-0.91-2.267-3.919-2.575-5.225-0.539-2.282-2.347-7.589-7.481-7.589s-6.942 5.308-7.481 7.589c-0.308 1.303-2.041 4.315-2.575 5.225z"></path>
<path d="M13.055 31.133c-0.032 0-0.066-0.002-0.097-0.009-0.173-0.032-0.319-0.149-0.39-0.308l-2.667-6c-0.095-0.214-0.039-0.466 0.138-0.62 0.178-0.155 0.434-0.174 0.633-0.049l5.333 3.332c0.138 0.086 0.228 0.23 0.246 0.393 0.019 0.161-0.037 0.322-0.153 0.437l-2.667 2.667c-0.1 0.101-0.237 0.157-0.377 0.157zM11.583 25.975l1.644 3.697 1.644-1.644-3.287-2.053z"></path>
<path d="M18.387 31.133c-0.14 0-0.277-0.055-0.378-0.157l-2.667-2.667c-0.114-0.113-0.172-0.275-0.153-0.437 0.018-0.16 0.11-0.306 0.245-0.391l5.333-3.333c0.201-0.123 0.455-0.105 0.633 0.050 0.177 0.154 0.234 0.404 0.138 0.619l-2.667 6c-0.070 0.16-0.217 0.275-0.389 0.308-0.031 0.005-0.064 0.009-0.096 0.009zM16.572 28.028l1.644 1.644 1.644-3.697-3.287 2.053z"></path>
</svg>
`;

const FILE_ICON = `
<svg id="file" width="32" height="32" viewBox="0 0 32 32" x="280" y="1624">
<path d="M27.733 32h-23.467c-0.294 0-0.533-0.24-0.533-0.533v-23.467c0-0.142 0.057-0.277 0.156-0.378l7.467-7.467c0.1-0.1 0.236-0.156 0.378-0.156h16c0.293 0 0.533 0.239 0.533 0.533v30.933c0 0.293-0.24 0.533-0.533 0.533zM4.8 30.933h22.4v-29.867h-15.246l-7.154 7.154v22.713z"></path>
<path d="M11.733 8.533h-6.933c-0.294 0-0.533-0.239-0.533-0.533s0.239-0.533 0.533-0.533h6.4v-6.4c0-0.294 0.239-0.533 0.533-0.533s0.533 0.239 0.533 0.533v6.933c0 0.294-0.239 0.533-0.533 0.533z"></path>
</svg>
`;

const SEARCH_ICON = `
<svg id="magnifi_glass2" width="32" height="32" viewBox="0 0 32 32" x="896" y="2632">
<path d="M12.445 23.759c-3.015 0-5.852-1.178-7.984-3.318-4.404-4.42-4.404-11.61 0-16.027 2.132-2.142 4.969-3.322 7.984-3.322 3.017 0 5.853 1.18 7.986 3.322 4.404 4.417 4.404 11.606 0 16.026-2.133 2.142-4.969 3.319-7.986 3.319zM12.445 2.159c-2.732 0-5.299 1.068-7.229 3.007-3.989 4.002-3.989 10.516 0 14.522 1.932 1.937 4.499 3.004 7.229 3.004 2.732 0 5.3-1.067 7.232-3.005 3.987-4.004 3.987-10.519 0-14.521-1.933-1.938-4.5-3.007-7.232-3.007z"></path>
<path d="M28.737 30.908c-0.533 0-1.036-0.208-1.412-0.587l-9.125-9.161 2.927-2.939 9.132 9.162c0.779 0.781 0.779 2.052-0.002 2.832l-0.105 0.106c-0.379 0.378-0.879 0.587-1.414 0.587zM19.707 21.163l8.375 8.406c0.35 0.35 0.964 0.352 1.316 0l0.105-0.106c0.365-0.366 0.367-0.959 0.002-1.326l-8.378-8.402-1.421 1.427z"></path>
</svg>
`;

const HOME_ICON = `
<svg id="home" width="32" height="32" viewBox="0 0 32 32" x="224" y="2240">
<path d="M29.106 32h-9.6c-0.294 0-0.533-0.24-0.533-0.533v-9.067h-5.333v9.067c0 0.293-0.239 0.533-0.533 0.533h-9.6c-0.294 0-0.533-0.24-0.533-0.533v-16.533h-2.306c-0.222 0-0.42-0.137-0.498-0.343-0.079-0.207-0.021-0.441 0.144-0.588l15.286-13.6c0.196-0.175 0.49-0.18 0.692-0.015l3.749 3.077v-2.931c0-0.294 0.239-0.533 0.533-0.533h5.333c0.293 0 0.533 0.239 0.533 0.533v8.439l5.264 5.042c0.157 0.151 0.207 0.382 0.126 0.585-0.082 0.202-0.278 0.334-0.496 0.334h-1.695v16.533c0 0.293-0.239 0.533-0.533 0.533zM20.039 30.933h8.533v-16.533c0-0.294 0.239-0.533 0.533-0.533h0.9l-4.468-4.282c-0.106-0.1-0.163-0.24-0.163-0.385v-8.133h-4.267v3.525c0 0.206-0.117 0.395-0.305 0.482-0.187 0.087-0.407 0.062-0.566-0.069l-4.267-3.503-13.9 12.365h1.439c0.294 0 0.533 0.239 0.533 0.533v16.533h8.533v-9.067c0-0.293 0.239-0.533 0.533-0.533h6.4c0.294 0 0.533 0.24 0.533 0.533v9.067h-0.003z"></path>
</svg>
`;