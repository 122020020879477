import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatSliderChange } from "@angular/material/slider";
import { HypecastAuthenticationService } from "@app/shared/authentication/services/authentication.service";
import { BaseComponent } from "@app/shared/base/components/base-component";
import { __ } from "@app/shared/functions/object.functions";
import { PodcastType } from "@app/shared/models/classes/Podcast";
import { MinuteSecondsPipe } from "@app/shared/pipes/minute-seconds.pipe";
import { ApiUrlService } from "@app/shared/services/api-url.service";
import { Howl } from "howler";
import { interval, merge, NEVER, Subject, Subscription } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { AudioPlayerService, QueueItem } from "../audio-player-control/audio-player.service";
import { FilesService } from "@app/shared/services/files.service";

@Component({
  selector: "hypecast-audio-player",
  templateUrl: "./audio-player.component.html",
  styleUrls: ["./audio-player.component.scss"],
})
export class AudioPlayerComponent extends BaseComponent implements OnInit, OnDestroy {
  PodcastType = PodcastType;

  sound: Howl;

  duration: number;

  max: number = 0;

  isDragging: boolean = false;

  min: number = 0;

  @Input() disabled: boolean = true;

  stepSize: number = 1; // 1 second

  seek$: Subject<number> = new Subject<number>();

  isErroneous: boolean = false;

  isPlaying: boolean = false;

  isPlaying$: Subject<boolean> = new Subject<boolean>();

  isDraggingSlider$: Subject<boolean> = new Subject<boolean>();

  sliderControl: UntypedFormControl;

  sliderValueChangeSubscription: Subscription;

  currentItem: QueueItem;

  streamStart: any;
  streamEnd: any;
  secondsStreamed: number;
  private trackListenInterval: any;
  isListened: boolean = false;

  ngOnInitHasRun: boolean = false;

  @Output() playClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() pauseClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output() endReached: EventEmitter<number> = new EventEmitter<number>();

  @Output() trackEventSent: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild("timer") timer: ElementRef;

  @ViewChild("slider", { read: ElementRef }) slider: ElementRef;

  private _src: string;
  @Input()
  public get src(): string {
    return this._src;
  }

  public set src(value: string) {
    this._src = value;
    this.isListened = false;
    if (this.ngOnInitHasRun === true) {
      this.loadAudio();
    }
  }

  constructor(
    private authenticationService: HypecastAuthenticationService,
    private minuteSecondsPipe: MinuteSecondsPipe,
    private apiUrlService: ApiUrlService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private audioPlayerService: AudioPlayerService,
    private filesService: FilesService
  ) {
    super();

    this.sliderControl = new UntypedFormControl(0);

    super.addSubscription(
      this.sliderControl.valueChanges.subscribe((position: number) => {
        this.seek(position);
      })
    );

    super.addSubscription(
      this.audioPlayerService.isPlaying$.subscribe((isPlaying: QueueItem) => {
        this.currentItem = isPlaying;

        if (this.isPlaying !== isPlaying.isPlaying) {
          this.isPlaying = this.currentItem.isPlaying;
          this.isPlaying$.next(this.currentItem.isPlaying);

          if (!__.IsNullOrUndefined(this.sound)) {
            if (this.isPlaying === true && this.sound.playing() === false) {
              this.sound.play();
            }
            if (this.isPlaying === false && this.sound.playing() === true) {
              this.sound.pause();
            }
          }
        }
      })
    );

    super.addSubscription(
      merge(
        this.isPlaying$.pipe(
          map((isPlaying: boolean) => {
            return { name: "isPlaying", value: isPlaying };
          })
        ),
        this.isDraggingSlider$.pipe(
          map((isDraggingSlider: boolean) => {
            return { name: "isDraggingSlider", value: isDraggingSlider };
          })
        )
      )
        .pipe(
          switchMap(($event: { name: string; value: boolean }) => {
            return this.isPlaying === true && this.isDragging === false
              ? interval(200).pipe(
                  map(() => {
                    return this.sound.seek() || 0;
                  })
                )
              : NEVER;
          })
        )
        .subscribe((position: number) => {
          this.sliderControl.setValue(position, { emitEvent: false });
        })
    );
  }

  ngOnInit() {
    this.ngOnInitHasRun = true;
    this.loadAudio();
  }

  loadAudio(): void {
    if (!__.IsNullOrUndefined(this.sound)) {
      this.pause();
      this.streamEnd = this.sound.seek();
      this.sound.unload();
      this.sound = null;
    }

    setTimeout(() => {
      if (!__.IsNullOrUndefinedOrEmpty(this.src)) {
        this.isLoading = true;

        let src = "";
        let isExternal = false;

        if (this.src.startsWith("http")) {
          // Assuming that this is an external podcast audio file src
          src = `${this.src}`;
          isExternal = true;
          this.sound = new Howl({
            src: [src],
            pool: 1,
            preload: "metadata",
            html5: true,

            onloaderror: () => {
              this.disabled = true;
              this.duration = 0;
            },
            onplay: () => {
              this.audioPlayerService.play();
              requestAnimationFrame(this.step.bind(this));
              this.playClicked.emit(this.isListened);
              this.isListened = true;
              this.streamStart = this.sound.seek();
              this.isPlaying = true;
              this.isPlaying$.next(true);
              this.currentItem.isPlaying = true;
            },
            onend: () => {
              this.onEnd();
            },
            onpause: () => {
              this.onPause();
            },
            onseek: () => {
              // Start upating the progress of the track.
              requestAnimationFrame(this.step.bind(this));
            },
            // TODO: What happens, if the bearer token expired?
          });
          if (this.sound.state() === "loaded") {
            // The file has been loaded from the cache
            this.afterLoad();
          }

          this.sound.once("load", () => {
            this.afterLoad();
          });

          this.sound.once("end", () => {
            this.onPause();
            this.sliderControl.setValue(0, { emitEvent: false });
          });

          this.sound.once("loaderror", () => {
            this.isLoading = false;
            this.isErroneous = true;
          });
        } else {
          // Assuming that this is an internal podcast audio file src
          // src = `${this.apiUrlService.getCdnUrl()}api/v1/files/${this.src}`;

          this.filesService.getPresignedUrl(this.src).subscribe(
            (signedUrl) => {
              this.isLoading = false;

              this.sound = new Howl({
                src: [signedUrl],
                pool: 1,
                html5: true,

                onloaderror: () => {
                  this.disabled = true;
                  this.duration = 0;
                },
                onplay: () => {
                  this.audioPlayerService.play();
                  requestAnimationFrame(this.step.bind(this));
                  this.playClicked.emit(this.isListened);
                  this.isListened = true;
                  this.streamStart = this.sound.seek();
                  this.isPlaying = true;
                  this.isPlaying$.next(true);
                  this.currentItem.isPlaying = true;

                  this.trackListenInterval = setInterval(() => {
                    this.streamStart = this.sound.seek();
                    this.trackEventSent.emit(5);
                  }, 5000);
                },
                onend: () => {
                  this.onEnd();
                },
                onpause: () => {
                  this.onPause();
                },
                onseek: () => {
                  // Start upating the progress of the track.
                  requestAnimationFrame(this.step.bind(this));
                },
                // TODO: What happens, if the bearer token expired?
              });
              if (this.sound.state() === "loaded") {
                // The file has been loaded from the cache
                this.afterLoad();
              }

              this.sound.once("load", () => {
                this.afterLoad();
              });

              this.sound.once("end", () => {
                this.onPause();
                this.sliderControl.setValue(0, { emitEvent: false });
              });

              this.sound.once("loaderror", () => {
                this.isLoading = false;
                this.isErroneous = true;
              });
            },
            (error) => {
              this.isLoading = false;
              console.error("Error fetching signed URL:", error);
            }
          );
        }
      } else {
        throw new Error("[AUDIO PLAYER] No source was provided");
      }
    }, 0);
  }

  ngOnDestroy() {
    if (!__.IsNullOrUndefined(this.sound)) {
      this.sound.stop();
      this.sound.unload();
    }
  }

  afterLoad(): void {
    this.isLoading = false;
    this.disabled = false;
    this.duration = this.sound.duration();
    this.max = this.duration;

    this.sound.play();
    this.isPlaying = true;
    this.isPlaying$.next(true);

    setTimeout(() => {
      const observer = new MutationObserver((mutationsList: any[], _observer: any) => {
        const classChanges = mutationsList.filter((q) => q.attributeName === "class");

        if (classChanges.length > 0) {
          if (this.slider.nativeElement.className.includes("mat-slider-sliding")) {
            this.isDragging = true;
            this.isDraggingSlider$.next(true);

            // Set up a subscription to the slider value changes to set the timer to the current slider value
            // while the slider thumb is being dragged
            if (__.IsNullOrUndefined(this.sliderValueChangeSubscription)) {
              this.sliderValueChangeSubscription = this.sliderControl.valueChanges.subscribe((value: number) => {
                this.sound.seek(value);
              });
              super.addSubscription(this.sliderValueChangeSubscription);
            }
          } else {
            this.isDragging = false;
            this.isDraggingSlider$.next(false);

            // Remove the slider value changes subscription as soon as the drag handle is released
            if (!__.IsNullOrUndefined(this.sliderValueChangeSubscription)) {
              this.sliderValueChangeSubscription.unsubscribe();
              this.sliderValueChangeSubscription = null;
            }
          }
        }
      });

      observer.observe(this.slider.nativeElement, { attributes: true });
    }, 10);
  }

  setTimerWhenDragging($event: MatSliderChange): void {
    if (this.isDragging === true) {
      this.setTimer($event.value);
    }
  }

  step(): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.isDragging === false) {
        this.setTimer();
      }

      if (!__.IsNullOrUndefined(this.sound)) {
        if (this.sound.playing()) {
          requestAnimationFrame(this.step.bind(this));
        }
      }
    });
  }

  setTimer(value?: number) {
    if (__.IsNullOrUndefined(value)) {
      if (__.IsNullOrUndefined(this.sound)) {
        value = 0;
      } else {
        value = (this.sound.seek() as number) || 0;
      }
    }
    this.timer.nativeElement.innerHTML = this.minuteSecondsPipe.transform(value as number);
  }

  seek(position: number): void {}

  play(): void {
    if (this.sound.playing() === false && this.duration > 0) {
      this.sound.play();
    }
  }

  pause(): void {
    if (this.sound.playing() === true && this.duration > 0) {
      this.sound.pause();
    }
  }

  onEnd(): void {
    if (this.trackListenInterval) {
      clearInterval(this.trackListenInterval);
    }

    this.secondsStreamed = Math.abs(Math.round(this.duration - this.streamStart));
    this.sound.stop();
    this.sound.pause();
    this.endReached.emit(this.secondsStreamed);
  }

  private onPause(): void {
    if (this.trackListenInterval) {
      clearInterval(this.trackListenInterval);
    }

    this.audioPlayerService.pause();

    if (!__.IsNullOrUndefined(this.sound)) {
      this.streamEnd = this.sound.seek();
    }
    if (this.streamStart < this.streamEnd) {
      this.secondsStreamed = Math.abs(Math.round(this.streamEnd - this.streamStart));
    } else {
      this.secondsStreamed = 0;
    }
    this.pauseClicked.emit(this.secondsStreamed);
    this.isPlaying = false;
    this.isPlaying$.next(false);

    if (!__.IsNullOrUndefined(this.currentItem)) {
      this.currentItem.isPlaying = false;
    }

    this.cdr.detectChanges();
  }
}
