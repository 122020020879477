import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {merge, Observable, of} from 'rxjs';
import {catchError, filter, map, mergeMap, take, timeout} from 'rxjs/operators';
import {IOAuthStorage} from '../types';
import {OAuthResourceServerErrorHandler} from './resource-server-error-handler';
import {IOAuthService} from "@app/shared/authentication/angular-oauth-oidc/ioauth-service";

@Injectable()
export class DefaultOAuthInterceptor implements HttpInterceptor {
  constructor(
    private authStorage: IOAuthStorage,
    private oAuthService: IOAuthService,
    private errorHandler: OAuthResourceServerErrorHandler,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();

    /* if (
      !this.checkUrl(url)
    ) {
      return next.handle(req);
    } */

    // const sendAccessToken = this.moduleConfig.resourceServer.sendAccessToken;

    // if (!sendAccessToken) {
    //   return next
    //     .handle(req)
    //     .pipe(catchError(err => this.errorHandler.handleError(err)));
    // }

    return merge(
      of(this.oAuthService.getAccessToken()).pipe(
        filter(token => (token ? true : false))
      ),
      this.oAuthService.events.pipe(
        filter(e => e.type === 'token_received'),
        timeout(this.oAuthService.waitForTokenInMsec || 0),
        catchError(_ => of(null)), // timeout is not an error
        map(_ => this.oAuthService.getAccessToken())
      )
    ).pipe(
      take(1),
      mergeMap(token => {
        if (token) {
          const header = 'Bearer ' + token;
          const headers = req.headers.set('Authorization', header);
          req = req.clone({headers});
        }

        return next
          .handle(req)
          .pipe(catchError(err => this.errorHandler.handleError(err)));
      })
    );
  }

  /* private checkUrl(url: string): boolean {
    // if (this.moduleConfig.resourceServer.customUrlValidation) {
    //   return this.moduleConfig.resourceServer.customUrlValidation(url);
    // }

    if (environment.serverUrl + 'api') {
      if (environment.environmentName === 'DEV') {
        return url.startsWith(environment.serverUrl + 'api');
      }
      return url.startsWith(`${this.tenantService.getTenant().apiUrl}api`);
    }

    return true;
  } */
}
